import { NumberFormatBase, useNumericFormat } from "react-number-format";
import { utils } from "../utils/utils";

const NumericFormat = (props) => {
  let value = props.value;

  // if the received value has more than 2 digits after the dot, apply the roundBasedOnThirdDecimalDigit method 
  // to avoid issues with decimal representation that javaScript uses with the IEEE 754 standard
  const numberString = value.toString();
  const decimalPart = numberString.split(".")[1];
  if (decimalPart && decimalPart.length > 2) {
    value = utils.roundBasedOnThirdDecimalDigit(value);
  }
  const newProps = { ...props, value };
  const { ...numberFormatBaseProps } = useNumericFormat(newProps);
  return <NumberFormatBase {...numberFormatBaseProps} />;
};

export default NumericFormat;
